import {
  createRouter,
  createWebHashHistory
} from 'vue-router';
import store from "../store";

const routes = [{
    path: '/',
    // component: () => import('../pages/home/index.vue')
    redirect: "/home"
  }, {
    path: '/home',
    name: 'home',
    component: () => import('../pages/home/index.vue')
  }, {
    path: '/index',
    name: 'index',
    component: () => import('../pages/index/index.vue')
  },
  {
    path: '/applyList',
    name: 'applyList',
    component: () => import('../pages/applyList/index.vue')
  }, {
    path: '/applyList/add',
    name: 'applyAdd',
    component: () => import('../pages/applyList/add/index.vue')
  }, {
    path: '/applyList/view',
    name: 'applyView',
    component: () => import('../pages/applyList/view/index.vue')
  }, {
    path: '/user',
    name: 'user',
    component: () => import('../pages/user/index.vue')
  }, {
    path: '/restList',
    name: 'restList',
    component: () => import('../pages/restList/index.vue')
  }, {
    path: '/tiaoXiuApply',
    name: 'tiaoXiuApply',
    component: () => import('../pages/restList/apply.vue')
  }, {
    path: '/tiaoXiuApplyList',
    name: 'tiaoXiuApplyList',
    component: () => import('../pages/restList/tiaoXiuApplyList.vue')
  }, {
    path: '/record',
    name: 'record',
    component: () => import('../pages/record/index.vue')
  }, {
    path: '/record/detail',
    name: 'recordDetail',
    component: () => import('../pages/record/detail.vue')
  }, {
    path: '/baiWenBd/baiWenBdList',
    name: 'baiWenBdList',
    component: () => import('../pages/baiWenBd/baiWenBdList.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes //路由信息
});


router.beforeEach(async (to, from, next) => {
  const userDataStore = store.getters["user/getUserData"];
  const userDataLocal = store.getters["user/getLocalUserData"];
  if (!userDataStore || !userDataStore.Guid) {
    if (process.env.NODE_ENV == "development") {
      if (userDataLocal) {
        store.commit("user/setIsLogin", true);
        store.commit("user/setUserData", userDataLocal);
        next();
        return;
      } else {
        const devLogin = await store.dispatch("user/loginTest");
        next();
        return;
      }
    } else {
      await store.dispatch("user/login").then((res) => {
        console.log("钉钉登录");
        console.log('from', from)
        console.log('to', to)
        next();
        return;
      });
    }
  }
  next();
});
export default router