import request from "@/utils/request";

export default {
  async getList(TypeCode) {
    const url = `/XT/XT_PublicCode/GetList`;
    const res = await request.post(url, {
      TypeCode: TypeCode,
      Zfpb: "0",
    });
    return res;
  },
  async geTypetList() {
    const url = `/XT/XT_PublicCodeType/GetList`;
    const res = await request.post(url, {
      SystemType: "XT",
    });
    return res;
  },
  async geOrganizationList() {
    const url = `/XT/XT_OrganizeInfo/GetList`;
    const res = await request.post(url, {});
    return res;
  },
  async loginTest(authCode) {
    const url = `/Login/DoLoginTest`;
    const res = await request.post(url, {
      // userGuid: "016322280236520862",  //波  党政办
      userGuid: "01160420263126511380",  //柯
      // userGuid: "2518241526109160",  //龙
      // userGuid: "09454702181148538",  //赵敏
      // userGuid: "212132183026183022",  //李巍巍
      // userGuid: "050305475133250350",  //jzh
      // userGuid: "0336422024758202",   //涛  健康事业发展部
      // userGuid: "021466672824361503",   //徐景霞
    });
    return res;
  },
  async login(authCode) {
    const url = `/Login/DoLogindd`;
    const res = await request.post(url, {
      code: authCode,
    });
    return res;
  },
  async getPerson(params) {
    const url = `/XT/XT_User/GetUserListByOrganizeGuid`;
    const res = await request.post(url, {
      OrganizeGuid: params.OrganizeGuid,
      Wgjdypb: params.Wgjdypb,
    });
    return res;
  },
  async uploadFile(params) {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const url = `/Upload/DoUpload`;
    const res = await request.postWithConfig(
      url, {
        RelationTableGuid: params.RelationTableGuid,
        TableName: "DB_WAEDROUNDS",
        FileType: "cfdInfo",
        UploadUserGuid: params.UserGuid,
        File: params.File,
      },
      config
    );
    return res;
  },
  async getFiles(params) {
    const url = `/Upload/GetUploadList`;
    const res = await request.post(url, {
      RelationTableGuid: params.Guid,
      TableName: "DB_WAEDROUNDS",
      Type: "cfdInfo",
    });
    return res;
  },
  async deleteFile(Guid) {
    const url = `/Upload/Delete`;
    const res = await request.post(url, {
      Guid: Guid,
    });
    return res;
  },
  async notification(params) {
    const url = `/API/DingTalk/Notification_Task`;
    const res = await request.post(url, {
      TaskGuid: params.TaskGuid,
      NotificationType: 2,
    });
    return res;
  },
  // 值班类型
  async getTypeList() {
    const params = {
      Jjrnf: '',
      Jjrmc: '',
      Platform: '',
      page: 1,
      rows: 100
    }
    const url = `/PB_DutyType/GetList`;
    const res = await request.post(url, params);
    // Guid为1的总值班排最前面
    // 创建一个原始索引的映射，用于在排序时保持其他元素的顺序
    const originalOrder = res.rows.map((item, index) => ({ item, index }));
    // 自定义排序函数
    originalOrder.sort((a, b) => {
      if (a.item.Guid === "1") {
        return -1; // Guid为1的排最前
      } else if (b.item.Guid === "1") {
        return 1; // Guid为1的排最前
      } else {
        return a.index - b.index; // 其他情况按原始顺序
      }
    });
    // 从排序后的映射中提取排序后的数组
    const sortedData = originalOrder.map(itemWithIndex => itemWithIndex.item);
    res.rows = sortedData
    return res;
  },

  // 值班人员
  async getOfficerList(params) {
    const url = `/PB/PB_DutyOfficer/GetList`;
    const res = await request.post(url, params);
    return res;
  },

  // 查询单个人的排班信息
  //Zbr 人员ID
  async getPbList(params) {
    const url = `/PB_Scheduling/GetPbList`;
    const res = await request.post(url, params);
    return res;
  },

  // 查询单个人的排班信息
  //Zbr 人员ID
  async getJsapiTicket() {
    const url = `/DingTalk/GetJsapiTicket`;
    const res = await request.post(url);
    return res;
  },

};