import { createApp } from "vue";
import App from "./App.vue";
import Vant from "vant";
import "vant/lib/index.css";
import "lib-flexible";
import VConsole from "vconsole";

import "./vantComponents.js";
import store from "./store";
import router from "./router/index.js";

import elementUi from 'element-plus';
import 'element-plus/dist/index.css';
import 'dayjs/locale/zh-cn'   //中文
import locale from 'element-plus/es/locale/lang/zh-cn';

if (process.env.NODE_ENV == "production") {
    const vConsole = new VConsole({
        theme: "dark",
    });
}
const app = createApp(App);
app.use(Vant);
app.use(router);
app.use(store);
app.use(elementUi, {locale});
app.mount("#app");
