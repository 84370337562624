import pubApi from "@/api/public";
import * as dd from "dingtalk-jsapi";

const state = () => ({
  tabIndex: 0,
  renWuType: [],
  renWuLaiYuan: [],
  organizationList: [],
  touSuLaiYuan: [],
  userData: {},
  isLogin: false,
  token: "",
  ddMap:{},
});

// getters
const getters = {
  getTabIndex: (state, getters, rootState) => {
    return state.tabIndex;
  },
  getRenWuType: (state, getters, rootState) => {
    return state.renWuType;
  },
  getRenWuLaiYuan: (state, getters, rootState) => {
    return state.renWuLaiYuan;
  },
  getOrganizationList: (state, getters, rootState) => {
    return state.organizationList;
  },
  getTouSuLaiYuan: (state, getters, rootState) => {
    return state.touSuLaiYuan;
  },
  getUserData: (state, getters, rootState) => {
    return state.userData;
  },
  getLocalUserData(state, getters, rootState) {
    let userData;
    if (process.env.NODE_ENV == "development") {
      console.log(localStorage.getItem("currentUser"));
      userData = JSON.parse(localStorage.getItem("currentUser"));
    } else {
      dd.getStorage({
        key: `currentUser`,
        success: (res) => {
          const { data } = res;
          userData = JSON.parse(data);
        },
      });
    }
    return userData;
  },
  getIsLogin: (state, getters, rootState) => {
    return state.isLogin;
  },
  getDDMap: (state, getters, rootState) => {
    return state.ddMap;
  },
};

// actions
const actions = {
  login({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      console.log(process.env.VUE_APP_DING_CORPID);
      dd.getAuthCode({
        corpId: process.env.VUE_APP_DING_CORPID,
        success: async (res) => {
          if (res) {
            console.log(`res:${JSON.stringify(res)}`);
            const { code } = res;
            console.log(`code:${code}`);
            await dispatch("getUserData", code);
            // getUserData(code);
            resolve();
          }
        },
        fail: (err) => {
          console.log(`error:${err}`);
          this.isLoginFail = true;
          reject();
        },
        complete: () => {},
      });
    });
  },
  loginTest({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      if (process.env.NODE_ENV == "development") {
        pubApi
          .loginTest()
          .then((res) => {
            commit("setIsLogin", true);
            commit("setUserData", res.data);
            localStorage.setItem("currentUser", JSON.stringify(res.data));
            resolve(res.data);
          })
          .catch((err) => {
            reject();
          });
      }
    });
  },
  getUserData({ dispatch, commit }, authCode) {
    return new Promise((resolve, reject) => {
      console.log(`authCode:${authCode}`);
      pubApi.login(authCode).then((res) => {
        if (res.code === 1) {
          dd.setStorage({
            key: "currentUser",
            data: JSON.stringify(res.data),
          });
          commit("setIsLogin", true);
          commit("setUserData", res.data);
          resolve(res.data);
        }
      });
    });
  },
};

// mutations
const mutations = {
  setTabIndex(state, index) {
    state.tabIndex = index;
  },
  setRenWuType(state, type) {
    state.renWuType = type;
  },
  setRenWuLaiYuan(state, list) {
    state.renWuLaiYuan = list;
  },
  setOrganizationList(state, list) {
    state.organizationList = list;
  },
  setTouSuLaiYuan(state, type) {
    state.touSuLaiYuan = type;
  },
  setUserData(state, obj) {
    state.userData = obj;
    state.token = obj.Token;
  },
  setIsLogin(state, bool) {
    state.isLogin = bool;
  },
  setDDMap(state, map) {
    state.ddMap = map; // 更新 state 中的 map 属性
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
