import {
  createApp
} from 'vue';
import {
  Tabbar,
  TabbarItem,
  NavBar,
  Toast,
  Popup,
  Cell,
  CellGroup,
  DropdownMenu,
  DropdownItem,
  Icon,
  Field,
  Button,
  Form,
  Picker,
  Divider,
  Tab,
  Tabs,
  Calendar,
  Step,
  Steps,
  Empty,
  Loading,
  Notify,
  Uploader,
  Tag,
  Collapse,
  CollapseItem,
  Popover
} from "vant";

const app = createApp();
app.use(Collapse);
app.use(CollapseItem);
app.use(Tabbar);
app.use(TabbarItem);
app.use(NavBar);
app.use(Toast);
app.use(Popup);
app.use(Cell);
app.use(CellGroup);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Icon);
app.use(Field);
app.use(Button);
app.use(Form);
app.use(Picker);
app.use(Divider);
app.use(Tab);
app.use(Tabs);
app.use(Calendar);
app.use(Step);
app.use(Steps);
app.use(Empty);
app.use(Loading);
app.use(Notify);
app.use(Uploader);
app.use(Tag);
app.use(Popover);