import axios from "axios";
import QS from "qs"; // 引入qs模块，用来序列化post类型的数据
import store from "@/store/index";
// 环境的切换
// if (process.env.NODE_ENV == "development") {
//   axios.defaults.baseURL = "/api";
// } else if (process.env.NODE_ENV == "production") {
//   axios.defaults.baseURL = "https://hzetyydd.hzdsinfo.com";
// }

axios.defaults.baseURL = process.env.VUE_APP_BASE_API || "/api";
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

//请求拦截
axios.interceptors.request.use(
  (config) => {
    const token = store.state.user.token;
    token && (config.headers.Authorization = token);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export default {
  /**
   * get方法，对应get请求
   * @param {String} url [请求的url地址]
   * @param {Object} params [请求时携带的参数]
   */
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  /**
   * post方法，对应post请求
   * @param {String} url [请求的url地址]
   * @param {Object} params [请求时携带的参数]
   */
  post(url, params) {
    const UserGuid = store.state.user.userData.Guid;
    return new Promise((resolve, reject) => {
      axios
        .post(
          url,
          QS.stringify({
            // UserGuid: UserGuid,
            ...params,
          })
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postWithConfig(url, params, config) {
    const UserGuid = store.state.user.userData.Guid;
    return new Promise((resolve, reject) => {
      axios
        .post(
          url, {
            // UserGuid: UserGuid,
            ...params,
          },
          config
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};